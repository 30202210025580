export enum VehicleType {
  BigBus = 1,
  SmallBus = 2,
  DoubleDecker = 3,
  Taxi = 4,
  BigTaxi = 5,
  WheelchairTaxi = 6,
  StandbyBus = 7,
  StandbyWheelchairTaxi = 8,
  CustomerHost = 9,
  BusCoordinator = 10,
}
