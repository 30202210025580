export interface NotificationMessage {
  id: number;
  title: string;
  main: string;
  submain: string;
  type: string;
  notificationType: NotificationType;
  data: { [key: string]: unknown };
  link: string;
  groups: string[];
  isRead: boolean;
  created: string;
}

export enum NotificationType {
  DriveOrderInquiryCreated = 1,
  DriveOrderAssigned = 2,
  DriveOrderActivationReminderToSubcontractor = 3,
  PossibleLateFirstDeparture = 4,
  OrderCreated = 5,
  OrderEdited = 6,
  OrderCancelled = 7,
}

export interface DriveOrderInquiryCreatedType {
  numberOfInquiries: number;
}

export interface DriveOrderAssignedType {
  numberOfDriveOrders: number;
}

export interface DriveOrderActivationReminderToSubcontractorType {
  subcontractorOrderId: number;
  subcontractorId: number;
  driveOrderId: number;
  driverFullName: string;
  startTime: Date;
}

export interface PossibleLateFirstDeparture {
  orderId: number;
  driveOrderId: number;
  tripId: number;
  startTripStopTime: Date;
}

export interface OrderCreated {
  orderId: number;
  operatorName: string;
  orderType: string;
  isProductBased: boolean;
  routeName: string;
  routeStartPlaceDescription: string;
  routeEndPlaceDescription: string;
}

export interface OrderEdited {
  orderId: number;
  operatorName: string;
  orderType: string;
  isProductBased: boolean;
  routeName: string;
  routeStartPlaceDescription: string;
  routeEndPlaceDescription: string;
}

export interface OrderCancelled {
  orderId: number;
  operatorName: string;
  orderType: string;
  isProductBased: boolean;
  routeName: string;
  routeStartPlaceDescription: string;
  routeEndPlaceDescription: string;
}
