import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { format } from 'date-fns';
import { appConfigToken, libTranslationConfigToken } from '../../utils/injection-tokens';
import { ConfigModelWeb } from '../../assets/config/config.model';
import { LibTranslation } from '../../i18n/lib-translation.model';
import { OrderSearch } from '../../models/order-search';
import { Order, OrderReason, OrderWithQueryParm } from '../../models/order';
import { OrderState } from '../../models/order-state';
import { RouteVariantWithTimetable } from '../../models/route-variant';
import { RoundTrip } from '../../models/round-trip';
import { OrderRow } from '../../models/order-row';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  readonly baseUrl: string;

  constructor(
    private _http: HttpClient,
    @Inject(appConfigToken) private _appConfig: ConfigModelWeb,
    @Inject(libTranslationConfigToken) private _translation: LibTranslation,
  ) {
    const { apiUrl, apiVersionMain } = this._appConfig.apiUrlConfig;
    this.baseUrl = apiUrl + apiVersionMain + '/orders';
  }

  createDraftOrder(
    operatorOrganisationId: number,
    referenceId: string,
    reasonId: number,
    reasonAdditionalInfo: string,
    otherRequests: string,
  ): Observable<{ id: number }> {
    const url = `${this.baseUrl}/draft`;

    const body = {
      operatorOrganisationId: operatorOrganisationId,
      referenceId: referenceId,
      reasonId: reasonId,
      reasonAdditionalInfo: reasonAdditionalInfo,
      otherRequests: otherRequests,
    };

    return this._http.post<{ id: number }>(url, body);
  }

  updateOrder(id: string, referenceId: string, reasonId: number, reasonAdditionalInfo: string, otherRequests: string) {
    const url = `${this.baseUrl}/${id}`;

    const body = {
      referenceId: referenceId,
      reasonId: reasonId,
      reasonAdditionalInfo: reasonAdditionalInfo,
      otherRequests: otherRequests,
    };

    return this._http.put<{ id: number }>(url, body);
  }

  deleteOrder(id: string, reasonId: number, reasonAdditionalInfo: string) {
    const url = `${this.baseUrl}/${id}/state`;
    const body = {
      state: OrderState.Cancelled,
      cancellationDetails: {
        reasonEnum: reasonId,
        additionalInfo: reasonAdditionalInfo,
      },
    };
    return this._http.put<{ id: number }>(url, body);
  }

  /** GET orders from the server */
  getOrders$(search: OrderSearch): Observable<OrderWithQueryParm[]> {
    const { orderTypes, orderStates, orderIds, referenceIds, driveOrderIds, externalIds, tripIds, dateRange } = search;
    let url = this.baseUrl;
    url += `?OrderTypes=${orderTypes.toString()}`;
    url += `&OrderStates=${orderStates.toString()}`;
    url += `&OrderIds=${orderIds.toString()}`;
    url += `&ReferenceIds=${referenceIds.toString()}`;
    url += `&DriveOrderIds=${driveOrderIds.toString()}`;
    url += `&ExternalIds=${externalIds.toString()}`;
    url += `&TripIds=${tripIds.toString()}`;
    if (dateRange !== undefined) {
      const { fromDate, toDate } = dateRange;
      const dateFormat = 'yyyy-MM-ddTHH:mm:ss.SSSz';
      const from = format(fromDate, dateFormat);
      const to = format(toDate, dateFormat);
      url += '&StartDate=' + encodeURIComponent(from);
      url += '&EndDate=' + encodeURIComponent(to);
    }
    return this._http.get<OrderWithQueryParm[]>(url);
  }

  getOrder(orderId: string) {
    const url = `${this.baseUrl}/${orderId}`;
    return this._http.get<Order>(url);
  }

  getOrderReasons(): Observable<OrderReason[]> {
    const url = `${this.baseUrl}/reasons`;
    return this._http.get<OrderReason[]>(url);
  }

  getRouteVariantTimeTable(id: number): Observable<RouteVariantWithTimetable> {
    const url = `${this._appConfig.apiUrlConfig.apiUrl}${this._appConfig.apiUrlConfig.apiVersionMain}/timetables/routevariant/${id}`;
    return this._http.get<RouteVariantWithTimetable>(url);
  }

  addRoundTrip(orderId: number, roundTrip: RoundTrip) {
    return this._http.post(`${this.baseUrl}/${orderId}/orderrows/roundtrip`, roundTrip);
  }

  getProducts(orderId: number): Observable<OrderRow[]> {
    return this._http.get<OrderRow[]>(`${this.baseUrl}/${orderId}/orderrows`);
  }

  updateRoundTrip(orderRow: OrderRow): Observable<void> {
    return this._http.put<void>(
      `${this.baseUrl}/${orderRow.orderId}/orderrows/roundTrip/${orderRow.id}`,
      orderRow.roundTrip,
    );
  }

  deleteOrderRow(orderId: number, orderRowId: number): Observable<void> {
    return this._http.delete<void>(`${this.baseUrl}/${orderId}/orderrows/${orderRowId}`);
  }

  setOrderState(state: number, orderId: number): Observable<void> {
    const url = `${this.baseUrl}/${orderId}/state`;
    const body = { state: state };
    return this._http.put<void>(url, body);
  }
}
