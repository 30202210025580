import { inject, Injectable } from '@angular/core';
import { appConfigToken } from '../../utils/injection-tokens';
import { ConfigModelWeb } from '../../assets/config/config.model';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { RouteOperator } from '../../models/route-operator';
import { Routes } from '../../models/route';
import { RouteVariant, RouteVariants } from '../../models/route-variant';
import { RouteVariantShape } from '../../models/route-variant-shape';

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  private _baseUrl: string;
  private _appConfig: ConfigModelWeb = inject<ConfigModelWeb>(appConfigToken);
  private _httpClient = inject(HttpClient);

  constructor() {
    const { routeUrl, apiVersionRoute } = this._appConfig.apiUrlConfig;
    this._baseUrl = routeUrl + apiVersionRoute;
  }

  getOperators(): Observable<RouteOperator[]> {
    return this._httpClient
      .get<{
        operators: RouteOperator[];
      }>(this._baseUrl + '/Operators')
      .pipe(
        map((response) => {
          return response.operators;
        }),
      );
  }

  getRoutes(organisationId: string): Observable<Routes> {
    return this._httpClient.get<Routes>(this._baseUrl + '/routes?OperatorOrganisationId=' + organisationId);
  }

  getRouteVariants(routeId: string, operatorOrganisationId: string): Observable<RouteVariants> {
    const url = this._baseUrl + '/routes/' + routeId + '/variants?OperatorOrganisationId=' + operatorOrganisationId;
    return this._httpClient.get<RouteVariants>(url);
  }

  getRouteVariant(routeId: string, routeVariantId: string, organisationId: string): Observable<RouteVariantShape> {
    const url =
      this._baseUrl +
      '/routes/' +
      routeId +
      '/variants/' +
      routeVariantId +
      '?OperatorOrganisationId=' +
      organisationId;
    return this._httpClient.get<RouteVariantShape>(url);
  }

  saveRouteVariantShape(routeId: string, shape: RouteVariantShape): Observable<RouteVariantShape> {
    const url = this._baseUrl + '/routes/' + routeId + '/variants/';
    return this._httpClient.put<RouteVariantShape>(url, shape);
  }
}
