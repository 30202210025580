import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatListModule } from '@angular/material/list';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { format, formatISO, isSameDay, isSameHour, set } from 'date-fns';
import { libTranslationConfigToken } from '../../utils/injection-tokens';
import { LibTranslation } from '../../i18n/lib-translation.model';

@Component({
  selector: 'tbums-lib-date-time-picker',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatListModule,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    MatSelectModule,
    MatButtonModule,
    ReactiveFormsModule,
  ],
  template: `
    <mat-form-field
      subscriptSizing="dynamic"
      cdkOverlayOrigin
      #originOverlay="cdkOverlayOrigin"
      class="relative w-full"
      [ngClass]="{ 'relative z-[100]': showPanel() }"
    >
      <mat-label
        >{{ isStart ? libTranslation.search.filter.startDateTime : libTranslation.search.filter.endDateTime }}
      </mat-label>

      <input matInput type="text" readonly [formControl]="inputFormControl" (focus)="open()" class="cursor-pointer" />
    </mat-form-field>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="originOverlay"
      [cdkConnectedOverlayOpen]="showPanel()"
      cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
      (backdropClick)="close()"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayDisableClose]="true"
    >
      <div class="border-gray2 flex w-[260px] flex-col gap-2 rounded-b border-[1px] bg-white p-2 shadow-2xl">
        <mat-calendar
          [selected]="currentCalendarDate"
          (selectedChange)="dateChanged($event)"
          [minDate]="calendarMinDate"
          [maxDate]="calendarMaxDate"
          (click)="prev($event)"
        ></mat-calendar>
        <div class="flex flex-row items-center gap-1">
          <mat-form-field subscriptSizing="dynamic">
            <mat-label>{{ libTranslation.search.filter.hours }}</mat-label>
            <mat-select [formControl]="hoursFormControl" (selectionChange)="dateTimeChanged()">
              @for (hour of hourOptions; track hour) {
                <mat-option
                  [id]="hour.label"
                  [value]="hour.label"
                  [disabled]="hour.id < minHour() || hour.id > maxHour()"
                  >{{ hour.label }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <span>:</span>
          <mat-form-field subscriptSizing="dynamic">
            <mat-label>{{ libTranslation.search.filter.minutes }}</mat-label>
            <mat-select [formControl]="minutesFormControl" (selectionChange)="dateTimeChanged()">
              @for (minute of minuteOptions; track minute) {
                <mat-option
                  [id]="minute.label"
                  [value]="minute.label"
                  [disabled]="minute.id < minMinute() || minute.id > maxMinute()"
                  >{{ minute.label }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex flex-row" [ngClass]="{ 'justify-between': showReset, 'justify-end': !showReset }">
          @if (showReset) {
            <button mat-button class="text-blue4" (click)="resetTime()">
              {{ libTranslation.search.filter.resetTime }}
            </button>
          }
          <button mat-button color="primary" (click)="close()">{{ libTranslation.common.button.close }}</button>
        </div>
      </div>
    </ng-template>
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true,
    },
  ],
})
export class DateTimePickerComponent implements OnInit, ControlValueAccessor {
  @Input() isStart = false;
  @Input() handleAsString = false;
  @Input() showReset = true;
  @Output() dateTimeChange = new EventEmitter<Date>();

  libTranslation = inject<LibTranslation>(libTranslationConfigToken);
  formBuilder = inject(FormBuilder);
  inputFormControl = this.formBuilder.control('');
  hoursFormControl = this.formBuilder.control('');
  minutesFormControl = this.formBuilder.control('');
  calendarMaxDate: Date | undefined = undefined;
  calendarMinDate: Date | undefined = undefined;
  maxHour = signal<number>(23);
  minHour = signal<number>(0);
  minMinute = signal<number>(0);
  maxMinute = signal<number>(59);
  currentCalendarDate = new Date();

  showPanel = signal(false);
  hourOptions: { id: number; label: string }[] = Array.from({ length: 24 }, (e, i) => ({
    id: i,
    label: this.addPadding(i),
  }));
  minuteOptions: { id: number; label: string }[] = Array.from({ length: 60 }, (e, i) => ({
    id: i,
    label: this.addPadding(i),
  }));

  private _onChange!: (value: string | Date) => void;
  private _onTouched!: () => void;

  get currentDate(): Date {
    return this.currentCalendarDate || new Date();
  }

  @Input() set maxDate(value: Date | undefined) {
    this.calendarMaxDate = value;
    this._setMaxDate();
  }

  @Input() set minDate(value: Date | undefined) {
    this.calendarMinDate = value;
    this._setMinDate();
  }

  @Input() set date(selectedDate: Date | string) {
    if (typeof selectedDate === 'string') {
      selectedDate = new Date(selectedDate);
    }
    this.currentCalendarDate = selectedDate;
    this.minutesFormControl.setValue(this.addPadding(selectedDate.getMinutes()));
    this.hoursFormControl.setValue(this.addPadding(selectedDate.getHours()));
    this.setDateTimeInput();
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.inputFormControl.disable() : this.inputFormControl.enable();
  }

  ngOnInit() {
    this.setDateTimeInput();
  }

  dateChanged(date: Date | undefined | null) {
    if (date) {
      this.currentCalendarDate = date;
      this.dateTimeChanged();
    }
  }

  dateTimeChanged() {
    this._setMinDate();
    this._setMaxDate();
    this.apply();
  }

  addPadding(val: number): string {
    return val < 10 ? '0' + val : val.toString();
  }

  prev(event: MouseEvent) {
    event.stopPropagation();
  }

  setDateTimeInput() {
    const dateTime =
      format(this.currentDate, 'yyyy-MM-dd') + '  ' + this.hoursFormControl.value + ':' + this.minutesFormControl.value;
    this.inputFormControl.setValue(dateTime);
  }

  getCurrentSelectedDateTime() {
    return set(this.currentDate, {
      hours: parseInt(this.hoursFormControl.value!),
      minutes: parseInt(this.minutesFormControl.value!),
    });
  }

  resetTime() {
    this.minutesFormControl.setValue(this.isStart ? '00' : '59');
    this.hoursFormControl.setValue(this.isStart ? '00' : '23');
  }

  apply() {
    this.setDateTimeInput();
    this.dateTimeChange.emit(this.getCurrentSelectedDateTime());
    if (this._onChange) {
      this._onChange(
        this.handleAsString ? formatISO(this.getCurrentSelectedDateTime()) : this.getCurrentSelectedDateTime(),
      );
    }
    this.currentCalendarDate = this.getCurrentSelectedDateTime();
  }

  close() {
    this.showPanel.set(false);
  }

  open() {
    this.showPanel.set(true);
  }

  public registerOnChange(fn: (value: string | Date) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  writeValue(date: string): void {
    if (date) {
      const selectedDate = new Date(date);
      this.currentCalendarDate = selectedDate;
      this.minutesFormControl.setValue(this.addPadding(selectedDate.getMinutes()).toString());
      this.hoursFormControl.setValue(this.addPadding(selectedDate.getHours()).toString());
      this.setDateTimeInput();
      this._setMinDate();
      this._setMaxDate();
    }
  }

  private _setMinDate() {
    if (this.calendarMinDate && isSameDay(this.currentDate, this.calendarMinDate!)) {
      this.minHour.set(this.calendarMinDate.getHours());

      if (isSameHour(this.getCurrentSelectedDateTime(), this.calendarMinDate)) {
        this.minMinute.set(this.calendarMinDate.getMinutes());
      } else {
        this.minMinute.set(0);
      }
    } else {
      this.minHour.set(0);
      this.minMinute.set(0);
    }

    this.setDateTimeInput();
  }

  private _setMaxDate() {
    if (this.calendarMaxDate && isSameDay(this.currentDate, this.calendarMaxDate!)) {
      this.maxHour.set(this.calendarMaxDate.getHours());

      if (isSameHour(this.getCurrentSelectedDateTime(), this.calendarMaxDate)) {
        this.maxMinute.set(this.calendarMaxDate.getMinutes());
      } else {
        this.maxMinute.set(59);
      }
    } else {
      this.maxHour.set(23);
      this.maxMinute.set(59);
    }

    this.setDateTimeInput();
  }
}
