import { Injectable, inject } from '@angular/core';
import { translationStringSubstitution } from '../utils/translation-util';
import { libTranslationConfigToken } from '../utils/injection-tokens';
import { LibTranslation } from '../i18n/lib-translation.model';
import {
  NotificationType,
  DriveOrderInquiryCreatedType,
  DriveOrderAssignedType,
  DriveOrderActivationReminderToSubcontractorType,
  PossibleLateFirstDeparture,
  OrderCreated,
  OrderEdited,
  OrderCancelled,
} from '../models/notification-message';
import { format } from 'date-fns';
export interface ServerNotification {
  title: string;
  message: string;
  link: string;
  isExternal: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ServerNotificationService {
  private _translation = inject<LibTranslation>(libTranslationConfigToken);

  getFormatedNotification(notificationType: NotificationType, data: { [key: string]: unknown }): ServerNotification {
    let title: string;
    let message: string;
    let link: string;

    switch (notificationType) {
      case NotificationType.DriveOrderInquiryCreated:
        ({ title, message, link } = this.setDriveOrderInquiryNotification(data));
        break;
      case NotificationType.DriveOrderAssigned:
        ({ title, message, link } = this.setDriveOrderAssignedNotification(data));
        break;
      case NotificationType.DriveOrderActivationReminderToSubcontractor:
        ({ title, message, link } = this.setDriveOrderReminderToSubcontractorNotification(data));
        break;
      case NotificationType.PossibleLateFirstDeparture:
        ({ title, message, link } = this.setPossibleLateFirstDepartureNotification(data));
        break;
      case NotificationType.OrderCreated:
        ({ title, message, link } = this.setOrderCreatedNotification(data));
        break;
      case NotificationType.OrderEdited:
        ({ title, message, link } = this.setOrderEditedNotification(data));
        break;
      case NotificationType.OrderCancelled:
        ({ title, message, link } = this.setOrderCancelledNotification(data));
        break;

      default:
        title = '';
        message = '';
        link = '';
        break;
    }
    const isExternal = this.isExternalLink(link);
    return { title, message, link, isExternal };
  }

  isExternalLink(link: string): boolean {
    return !(link.indexOf('/order-new/') >= 0 || link.indexOf('/garage/') >= 0 || link.indexOf('planning') >= 0);
  }

  setDriveOrderAssignedNotification(data: { [key: string]: unknown }) {
    const driveOrderAssignedData = data as unknown as DriveOrderAssignedType;
    const numberOfDriveOrders = driveOrderAssignedData.numberOfDriveOrders;
    const title = this._translation.toolbar.notifications.driveOrderAssignedNotification.title;
    let message: string;
    const link = `${window.location.origin}/orders`;

    if (numberOfDriveOrders > 1) {
      message = translationStringSubstitution(
        this._translation.toolbar.notifications.driveOrderAssignedNotification.severalDriveOrdersMessage,
        numberOfDriveOrders.toString(),
      );
    } else {
      message = translationStringSubstitution(
        this._translation.toolbar.notifications.driveOrderAssignedNotification.oneDriveOrderMessage,
        numberOfDriveOrders.toString(),
      );
    }
    return { title, message, link };
  }

  setDriveOrderInquiryNotification(data: { [key: string]: unknown }) {
    const driveOrderInquiryCreatedData = data as unknown as DriveOrderInquiryCreatedType;
    const numberOfInquiries = driveOrderInquiryCreatedData.numberOfInquiries;
    const title = this._translation.toolbar.notifications.driveOrderInquiryCreatedNotification.title;
    let message: string;
    const link = 'planning';

    if (numberOfInquiries > 1) {
      message = translationStringSubstitution(
        this._translation.toolbar.notifications.driveOrderInquiryCreatedNotification.severalDriveOrdersMessage,
        numberOfInquiries.toString(),
      );
    } else {
      message = translationStringSubstitution(
        this._translation.toolbar.notifications.driveOrderInquiryCreatedNotification.oneDriveOrderMessage,
        numberOfInquiries.toString(),
      );
    }
    return { title, message, link };
  }

  setDriveOrderReminderToSubcontractorNotification(data: { [key: string]: unknown }) {
    const driveOrderActivationReminderData = data as unknown as DriveOrderActivationReminderToSubcontractorType;
    const driveOrderId = driveOrderActivationReminderData.driveOrderId;
    const subcontractorId = driveOrderActivationReminderData.subcontractorId;
    const subcontractorOrderId = driveOrderActivationReminderData.subcontractorOrderId;
    const startTime = new Date(driveOrderActivationReminderData.startTime);
    const driverFullName = driveOrderActivationReminderData.driverFullName;
    const title = this._translation.toolbar.notifications.driveOrderActivationReminderNotification.title;
    const link = `subcontractor/${subcontractorId}/subcontractor-orders/${subcontractorOrderId}/drive-order#${driveOrderId}`;

    const message = translationStringSubstitution(
      this._translation.toolbar.notifications.driveOrderActivationReminderNotification
        .driveOrderActivationReminderMessage,
      driverFullName.toString(),
      driveOrderId.toString(),
      startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }).toString(),
    );
    return { title, message, link };
  }

  setPossibleLateFirstDepartureNotification(data: { [key: string]: unknown }) {
    const { orderId, driveOrderId, tripId, startTripStopTime } = data as unknown as PossibleLateFirstDeparture;

    const time = format(new Date(startTripStopTime), 'HH:mm');
    const title = translationStringSubstitution(
      this._translation.toolbar.notifications.possibleLateFirstDepartureNotification.title,
      driveOrderId.toString(),
    );
    const message = translationStringSubstitution(
      this._translation.toolbar.notifications.possibleLateFirstDepartureNotification.message,
      driveOrderId.toString(),
      tripId.toString(),
      time,
    );
    const link = `/order-new/order/${orderId}/drive-order#${driveOrderId}`;

    return { title, message, link };
  }

  setOrderCreatedNotification(data: { [key: string]: unknown }) {
    const { orderId, isProductBased, routeName, routeStartPlaceDescription, routeEndPlaceDescription } =
      data as unknown as OrderCreated;

    const title = translationStringSubstitution(
      this._translation.toolbar.notifications.orderCreated.title,
      orderId.toString(),
    );
    const message = isProductBased
      ? translationStringSubstitution(this._translation.toolbar.notifications.orderCreated.productBasedMessage)
      : translationStringSubstitution(
          this._translation.toolbar.notifications.orderCreated.legacyMessage,
          routeName,
          routeStartPlaceDescription,
          routeEndPlaceDescription,
        );
    const link = isProductBased ? `/order/product-order/${orderId}` : `/order/${orderId}`;

    return { title, message, link };
  }

  setOrderEditedNotification(data: { [key: string]: unknown }) {
    const { orderId, isProductBased, routeName, routeStartPlaceDescription, routeEndPlaceDescription } =
      data as unknown as OrderEdited;

    const title = translationStringSubstitution(
      this._translation.toolbar.notifications.orderEdited.title,
      orderId.toString(),
    );
    const message = isProductBased
      ? translationStringSubstitution(this._translation.toolbar.notifications.orderEdited.productBasedMessage)
      : translationStringSubstitution(
          this._translation.toolbar.notifications.orderEdited.legacyMessage,
          routeName,
          routeStartPlaceDescription,
          routeEndPlaceDescription,
        );
    const link = isProductBased ? `/order/product-order/${orderId}` : `/order/${orderId}`;

    return { title, message, link };
  }

  setOrderCancelledNotification(data: { [key: string]: unknown }) {
    const { orderId, isProductBased, routeName, routeStartPlaceDescription, routeEndPlaceDescription } =
      data as unknown as OrderCancelled;

    const title = translationStringSubstitution(
      this._translation.toolbar.notifications.orderCancelled.title,
      orderId.toString(),
    );
    const message = isProductBased
      ? translationStringSubstitution(this._translation.toolbar.notifications.orderCancelled.productBasedMessage)
      : translationStringSubstitution(
          this._translation.toolbar.notifications.orderCancelled.legacyMessage,
          routeName,
          routeStartPlaceDescription,
          routeEndPlaceDescription,
        );
    const link = isProductBased ? `/order/product-order/${orderId}` : `/order/${orderId}`;

    return { title, message, link };
  }
}
