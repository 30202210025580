import { inject, Injectable } from '@angular/core';
import { LibTranslation } from '../i18n/lib-translation.model';
import { libTranslationConfigToken } from '../utils/injection-tokens';
import { ProductType } from '../models/product-type';

@Injectable({
  providedIn: 'root',
})
export class ProductTypeService {
  translation = inject<LibTranslation>(libTranslationConfigToken);

  productTypes = new Map<ProductType, string>([
    [ProductType.RoundTrip, this.translation.order.orderRow.productType.roundTrip],
    [ProductType.Transfer, this.translation.order.orderRow.productType.transfer],
  ]);

  getProductType(productTypeEnum: ProductType): string | undefined {
    return this.productTypes.get(productTypeEnum);
  }

  getAllProductTypes(): { id: number; label: string }[] {
    return [...this.productTypes].map(([id, label]) => ({ id, label }));
  }
}
