import { Driver } from './driver';
import { Vehicle } from './vehicle';
import { StateObject } from './state-object';
import { SubcontractorVacantType } from './garage-subcontractor';
import { ProductType } from './product-type';

export interface DriveOrder {
  id: number;
  orderId: number;
  orderRow: DriveOrderOrderRow | null;
  externalId: string;
  startTripStop: DriveOrderTripStop;
  endTripStop: DriveOrderTripStop;
  trips: DriveOrderTrip[];
  assignment: DriveOrderAssignment;
  currentState: DriveOrderStateObject;
  activated: DriveOrderStateObject;
  completed: DriveOrderStateObject;
  reportedComplete: DriveOrderStateObject;
  isReadyToReportCompleted: boolean;
  tripCount: number;
  vehicleTypeEnum: number;
}

export interface DriveOrderTrip {
  state: DriveOrderTripStateObject;
  checkedIn: DriveOrderTripStateObject;
  id: number;
  routeName: string;
  routeVariantName: string;
  tripStops: TripStop[];
  sequence: number;
  startTripStop: DriveOrderTripStop;
  endTripStop: DriveOrderTripStop;
  isReadyToCheckin: boolean;
  trainNumber: string;
  passengerCount: number;
  externalTripId: string;
  driveOrderTripAssignment: DriveOrderTripAssignment;
  cancellationDetails: CancellationDetails | null;
  tripType?: number;
}

export interface CancellationDetails {
  additionalInfo: string;
  reasonEnum: number;
}

// mappar mot DriveOrderTripStop OBS! Med litet o i order
export interface TripStop {
  id: number;
  placeName: string;
  placeDescription: string;
  sequence: number;
  distanceKmFromPrev: number;
  passTime: string;
  vehicleAtStopEnter: Date | null;
  vehicleAtStopExit: Date | null;
  disembarkingOnly: boolean;
  note: string;
  travellerNote: string;
}

export interface TripStopSummary {
  placeDescription: string;
  passTime: string;
  orderedTime: string;
}

// mappar mot owned type DriveOrderTripStop, förväxla ej med TripStop
export interface DriveOrderTripStop {
  placeDescription: string;
  passTime: string;
  orderedTime: string;
}

export interface DriveOrderAssignment {
  assignedVehicle: Vehicle | null;
  assignedSubcontractorId: number;
  assignedSubcontractorOrderId: number;
  assignedSubcontractorName: string;
  assignedSubcontractorCode: string;
  assignedSubcontractorVacantType: SubcontractorVacantType;
}

export interface DriveOrderAssigned {
  assignedDriveOrderId: number;
  assignedOrderId: number;
}

export interface DriveOrderTripAssignment {
  assignedDriver: Driver | null;
}

export enum DriveOrderState {
  Created = 1,
  OrderedFromNobina = 2,
  AssignedToDriverAndVehicle = 3,
  Activated = 4,
  ReportedComplete = 5,
  Completed = 6,
  Cancelled = 7,
}

export interface DriveOrderStateObject extends StateObject {
  stateEnum: DriveOrderState | -1;
}

export enum DriveOrderTripState {
  Created = 1,
  CheckedIn = 2,
  Completed = 3,
  OrderedFromNobina = 4,
  Cancelled = 5,
}

export interface DriveOrderTripStateObject extends StateObject {
  stateEnum: DriveOrderTripState | -1;
}

export interface DriveOrderOrderRow {
  productType: ProductType;
}
