import { ChangeDetectionStrategy, Component, DestroyRef, Inject, inject, Input } from '@angular/core';
import svLocale from 'date-fns/locale/sv';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { formatDistanceToNow, Locale, parseISO } from 'date-fns';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NotificationMessage } from '../../../models/notification-message';
import { NotificationService } from '../../../services/api/notification.service';
import { appConfigToken } from '../../../utils/injection-tokens';
import { ConfigModelWeb } from '../../../assets/config/config.model';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'tbums-lib-notification',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatMenuModule, MatListModule],
  template: ` <a
    class="py-3"
    mat-menu-item
    [ngClass]="{
      'bg-secondary/[.12] hover:bg-secondary/[.2]': !notification.isRead
    }"
    (click)="markNotificationAsRead()"
  >
    <mat-icon
      class="dot mr-1 h-3 w-3 min-w-[12px] rounded-full"
      [ngClass]="{
        'bg-transparent': notification.isRead,
        'bg-secondary': !notification.isRead
      }"
    ></mat-icon>
    <div class="cursor-pointer flex flex-col ml-2">
      <div class="text-sm">{{ notification.title }}</div>
      <div>{{ notification.main }}</div>
      <div class="text-xs">
        {{
          formatDistanceToNow(parseISO(notification.created), {
            addSuffix: true,
            locale: svLocale
          })
        }}
      </div>
    </div>
  </a>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  @Input({ required: true }) notification!: NotificationMessage;
  router = inject(Router);
  protected readonly formatDistanceToNow = formatDistanceToNow;
  protected readonly parseISO = parseISO;
  protected readonly svLocale: Locale = svLocale;

  private _destryRef = inject(DestroyRef);
  private _dialogRef = inject(MatDialogRef);
  private _baseRef = inject(APP_BASE_HREF);

  constructor(
    private _notificationService: NotificationService,
    @Inject(appConfigToken) public appConfig: ConfigModelWeb,
  ) {}

  markNotificationAsRead() {
    const { id, isRead, link } = this.notification;
    if (!isRead) {
      this._notificationService
        .setNotificationRead([id])
        .pipe(takeUntilDestroyed(this._destryRef))
        .subscribe(() => {
          this._notificationService.getNotifications();
          this._setLink(link);
        });
    } else {
      this._setLink(link);
    }
  }

  private _setLink(link: string) {
    if (link.indexOf('order-new') !== -1 && this._baseRef == '/order-new/') {
      const newLink = link.replace('order-new', '');
      this.router.navigateByUrl(newLink);
      this._dialogRef.close();
    } else {
      window.location.href = link;
      if (link.indexOf('#')) {
        setTimeout(() => window.location.reload(), 500);
      }
    }
  }
}
