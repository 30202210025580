import { OrderState } from './order-state';
import { OrderType } from './order-type';

export interface PlanningDriveOrders {
  filterOptions: PlanningFilter;
  totalItems: number;
  items: PlanningDriveOrder[];
}

export interface PlanningFilter {
  subcontractors: SubcontractorFilter[];
}

export interface OperatorFilter {
  id: number;
  name: string;
}

export interface SubcontractorFilter {
  id: number;
  name: string;
}

export interface PlanningDriveOrder {
  orderId: number;
  driveOrderId: number;
  externalId: string;
  orderReferenceId: string;
  startTime: string;
  endTime: string;
  startTripStopDate: string;
  startTripStopStation: string;
  endTripStopStation: string;
  estimatedDistanceKm: number;
  estimatedTimeMinutes: number; //minutes
  operatorId: number;
  operatorName: string;
  vehicleType: number;
  state: number;
  inquiries: DriveOrderInquiryStatus[];
  assignedSubcontractorId: number;
  assignedSubcontractorName: string;
  orderState: OrderState;
  orderType: OrderType;
  isProductBased: boolean;
}

export interface PlanningDriveOrderView extends PlanningDriveOrder {
  formattedTime: string;
  vehicleTypeName: string | undefined;
  driveOrderLink: string;
  orderLink: string;
}

export interface DriveOrderInquiryStatus {
  id: number;
  subcontractorId: number;
  subcontractorName: string;
  responseEnum: InquiryResponseEnum;
  creationComment: string;
  responseComment: string;
}

export enum InquiryResponseEnum {
  NoReply = 1,
  Accepted = 2,
  Rejected = 3,
  Tentative = 4,
}
