import { ChangeDetectionStrategy, Component, DestroyRef, Inject, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertService } from '../../services/alert.service';
import { appConfigToken, libTranslationConfigToken } from '../../utils/injection-tokens';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { A11yModule } from '@angular/cdk/a11y';
import { take } from 'rxjs';
import { LibTranslation } from '../../i18n/lib-translation.model';
import { LoadingService } from '../../services/loading.service';
import { OrderService } from '../../services/api/order.service';
import { UnboundOrderService } from '../../services/api/unbound-order.service';
import { DriveOrderService } from '../../services/api/drive-order.service';
import { OrderSearch } from '../../models/order-search';
import { UserRoles } from '../../models/user-roles';
import { AuthService } from '../../services/auth.service';
import { OrderWithQueryParm } from '../../models/order';
import { OrderState } from '../../models/order-state';
import { DriveOrder } from '../../models/drive-order';
import { UnboundOrder } from '../../models/unbound-order';
import { UnboundOrderState } from '../../models/unbound-order-state';
import { ConfigModelWeb } from '../../assets/config/config.model';

enum SearchType {
  Trip,
  DriveOrder,
  Order,
}

@Component({
  selector: 'tbums-lib-search',
  standalone: true,
  imports: [CommonModule, MatIconModule, FormsModule, MatButtonModule, MatInputModule, A11yModule],
  providers: [OrderService, DriveOrderService, UnboundOrderService],
  template: ` <div *ngIf="true" class="flex w-full flex-row flex-wrap lg:mt-0 lg:w-auto">
    <div class="w-full border-solid border-gray-400 pt-2 lg:w-14 lg:border-0 lg:py-0 lg:pr-0 xl:w-28">
      <select
        [(ngModel)]="searchType"
        matNativeControl
        required
        class="mr-2 h-9 w-full cursor-pointer rounded border-solid border-gray-400 pl-2 text-black outline-gray-400 active:outline-0 lg:rounded-r-none lg:border-r-0 lg:outline-1"
      >
        <ng-container *ngIf="!userIsUeTrafikledare">
          <option value="OrderId">
            {{ translation.toolbar.search.select.orderId }}
          </option>
          <option value="DriveOrderId">
            {{ translation.toolbar.search.select.driveOrderId }}
          </option>
          <option value="TripId">
            {{ translation.toolbar.search.select.tripId }}
          </option>
          <option value="UnboundOrderId">
            {{ translation.toolbar.search.select.unboundOrderId }}
          </option>
        </ng-container>
        <ng-container *ngIf="userIsUeTrafikledare">
          <option value="OrderId">
            {{ translation.toolbar.search.select.orderId }}
          </option>
          <option value="DriveOrderId">
            {{ translation.toolbar.search.select.driveOrderId }}
          </option>
          <option value="TripId">
            {{ translation.toolbar.search.select.tripId }}
          </option>
        </ng-container>
      </select>
    </div>
    <div class="flex w-full flex-row items-center py-2 lg:w-32 lg:py-0">
      <input
        [(ngModel)]="searchId"
        class="h-9 w-full rounded pl-3 pr-9 text-black outline-gray-400 focus:outline-none lg:rounded-l-none lg:border-l lg:outline-1"
        type="text"
        placeholder="Nummer"
        cdkFocusInitial
        (keydown.enter)="searchId.length < 1 || search()"
        (keydown.esc)="searchId = ''"
        maxlength="9"
      />
      <button
        mat-icon-button
        class="-ml-[42px] h-[36px] w-[36px] p-1.5 lg:-ml-[36px]"
        (click)="searchId.length < 1 || search()"
      >
        <mat-icon class="text-gray-400">search</mat-icon>
      </button>
    </div>
  </div>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit {
  userRoles = UserRoles;
  userIsUeTrafikledare = this.isUeTrafikledare();
  userHasRole!: boolean;
  public searchId = '';
  private _searchType = 'SEARCH_TYPE';
  private _destroyRef = inject(DestroyRef);

  constructor(
    private _authService: AuthService,
    private _orderService: OrderService,
    private _driveOrderService: DriveOrderService,
    private _unboundOrderService: UnboundOrderService,
    private _alertService: AlertService,
    private _loadingService: LoadingService,
    @Inject(libTranslationConfigToken) public translation: LibTranslation,
    @Inject(appConfigToken) public appConfig: ConfigModelWeb,
  ) {}

  get searchType(): string {
    const searchType = localStorage.getItem(this._searchType);
    if (searchType !== null && searchType !== 'undefined' && searchType !== '') {
      return searchType;
    } else {
      return 'OrderId';
    }
  }
  set searchType(searchType: string) {
    localStorage.setItem(this._searchType, searchType);
  }

  ngOnInit(): void {
    this.setUserHasRole();
  }

  setUserHasRole(): void {
    this.userHasRole = this.userHasAnyRole([
      this.userRoles.OperatorTrafikledare,
      this.userRoles.UETrafikledare,
      this.userRoles.NobinaTrafikledare,
      this.userRoles.NobinaLeveransansvarig,
      this.userRoles.BergkvaraTrafikledare,
    ]);
  }

  isUeTrafikledare(): boolean {
    return this.userHasAnyRole([UserRoles.UETrafikledare]);
  }

  search(): void {
    const orderSearch: OrderSearch = new OrderSearch();

    if (this.validateNumber(this.searchId)) {
      switch (this.searchType) {
        case 'OrderId': {
          orderSearch.orderIds = [+this.searchId];
          this.orderSearch(orderSearch);
          break;
        }
        case 'DriveOrderId': {
          orderSearch.driveOrderIds = [+this.searchId];
          this.orderSearch(orderSearch);
          break;
        }
        case 'TripId': {
          orderSearch.tripIds = [+this.searchId];
          this.orderSearch(orderSearch);
          break;
        }
        case 'UnboundOrderId': {
          this.unboundOrderSearch(this.searchId);
          break;
        }
        default: {
          break;
        }
      }
    }
  }

  navigateToOrder(order: OrderWithQueryParm, search: OrderSearch): void {
    const {
      id,
      currentState,
      isHastusPlanned,
      isProductBased,
      queriedTripId,
      queriedDriveOrderId,
      queriedStartPassTime,
    } = order;
    const searchType = this._getSearchType(search);
    let route = '/order';
    if (currentState.stateEnum === OrderState.Completed) {
      route = '/history';
    }
    if (isHastusPlanned && searchType === SearchType.Order) {
      route += '/planned-order';
    }
    if (isProductBased && searchType === SearchType.Order) {
      route += '/product-order';
    }

    if (queriedDriveOrderId === undefined || queriedDriveOrderId === null) {
      window.location.assign(route + `/${id}`);
    } else {
      route += `/${id}/drive-order`;
      let fragmentString = queriedDriveOrderId.toString();
      if (queriedTripId) {
        fragmentString += '_' + queriedTripId.toString();
      }
      route = `/order-new${route}`;
      const date = new Date(queriedStartPassTime).toISOString().slice(0, 10);
      window.location.replace(`${route}?id=${id}&date=${date}#${fragmentString}`);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  }

  navigateToSubcontractorOrder(driveOrder: DriveOrder, search: OrderSearch): void {
    const searchType = this._getSearchType(search);
    let route = `/subcontractor/${driveOrder.assignment.assignedSubcontractorId}/subcontractor-orders`;

    if (searchType === SearchType.Order) {
      window.location.assign(route + `/${driveOrder.assignment.assignedSubcontractorOrderId}`);
    } else {
      route += `/${driveOrder.assignment.assignedSubcontractorOrderId}/drive-order`;
      let fragmentString = driveOrder.id.toString();
      if (search.tripIds.length) {
        fragmentString += '_' + search.tripIds[0].toString();
      }
      route = `/order-new${route}`;
      window.location.replace(
        `${route}?date=${driveOrder.startTripStop.passTime.toString().slice(0, 10)}#${fragmentString}`,
      );
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  }

  unboundOrderSearch(unboundOrderId: string): void {
    this._loadingService
      .runWithLoader(this._unboundOrderService.getOrders$(undefined, +unboundOrderId))
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((orders: UnboundOrder[]) => {
        if (orders.length) {
          this.navigateToUnboundOrder(orders[0]);
        } else {
          this.showNotFoundMessage();
        }
      });
  }

  navigateToUnboundOrder(unboundOrder: UnboundOrder): void {
    const { stateEnum } = unboundOrder.currentState;
    let url = 'unboundorder';
    if (stateEnum === UnboundOrderState.Completed || stateEnum === UnboundOrderState.Cancelled) {
      url = '/history/' + url;
    }
    window.location.assign(url + `/${unboundOrder.id}`);
  }

  orderSearch(search: OrderSearch): void {
    if (this.isUeTrafikledare()) {
      this.callSubcontractorOrderService(search);
    } else {
      this.callOrderService(search);
    }
  }

  callSubcontractorOrderService(search: OrderSearch): void {
    this._authService.user$.pipe(take(1)).subscribe((user) => {
      const subcontractorId: number = +(user?.idTokenClaims?.['garageIds'] as string[])[0] || 0;
      const tripId = search.tripIds.length ? search.tripIds[0].toString() : undefined;

      const driveOrderId = search.driveOrderIds.length ? search.driveOrderIds[0].toString() : undefined;

      const orderId = search.orderIds.length ? search.orderIds[0].toString() : undefined;

      this._loadingService
        .runWithLoader(
          this._driveOrderService.getDriveOrders$(
            orderId,
            false,
            undefined,
            undefined,
            tripId,
            driveOrderId,
            subcontractorId.toString(),
          ),
        )
        .pipe(takeUntilDestroyed(this._destroyRef))
        .subscribe((driveOrders: DriveOrder[]) => {
          if (driveOrders.length && driveOrders[0].assignment.assignedSubcontractorId) {
            this.navigateToSubcontractorOrder(driveOrders[0], search);
          } else {
            this.showNotFoundMessage();
          }
        });
    });
  }

  callOrderService(search: OrderSearch): void {
    this._loadingService
      .runWithLoader(this._orderService.getOrders$(search))
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((orders) => {
        if (orders.length) {
          this.navigateToOrder(orders[0], search);
        } else {
          this.showNotFoundMessage();
        }
      });
  }

  validateNumber(value: string): boolean {
    if (!parseInt(value, 10)) {
      this.showNotANumberMessage();
      return false;
    } else {
      return true;
    }
  }

  showNotANumberMessage(): void {
    this._alertService.setError(this.translation.toolbar.search.alertMsg.notANumberMessage);
  }

  showNotFoundMessage(): void {
    this._alertService.setInfo(this.translation.toolbar.search.alertMsg.notFoundMessage);
  }

  userHasAnyRole(userRoles: UserRoles[]): boolean {
    return this._authService.userHasAnyRole(userRoles);
  }

  private _getSearchType(search: OrderSearch): SearchType {
    if (search.tripIds.length) {
      return SearchType.Trip;
    } else if (search.driveOrderIds.length) {
      return SearchType.DriveOrder;
    }
    return SearchType.Order;
  }
}
