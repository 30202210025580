import { inject, Injectable } from '@angular/core';
import { LibTranslation } from '../i18n/lib-translation.model';
import { libTranslationConfigToken } from '../utils/injection-tokens';
import { VehicleType } from '../models/vehicle-type';

@Injectable({
  providedIn: 'root',
})
export class VehicleTypeService {
  translation = inject<LibTranslation>(libTranslationConfigToken);

  vehicleTypes = new Map<number, string>([
    [VehicleType.BigBus, this.translation.vehicleTypes.bigBus],
    [VehicleType.SmallBus, this.translation.vehicleTypes.smallBus],
    [VehicleType.DoubleDecker, this.translation.vehicleTypes.doubleDecker],
    [VehicleType.Taxi, this.translation.vehicleTypes.taxi],
    [VehicleType.BigTaxi, this.translation.vehicleTypes.bigTaxi],
    [VehicleType.WheelchairTaxi, this.translation.vehicleTypes.wheelchairTaxi],
    [VehicleType.StandbyBus, this.translation.vehicleTypes.standbyBus],
    [VehicleType.StandbyWheelchairTaxi, this.translation.vehicleTypes.standbyWheelchairTaxi],
    [VehicleType.CustomerHost, this.translation.vehicleTypes.customerHost],
    [VehicleType.BusCoordinator, this.translation.vehicleTypes.busCoordinator],
  ]);

  getVehicleType(vehicleTypeEnum: number): string | undefined {
    return this.vehicleTypes.get(vehicleTypeEnum);
  }

  getAllVehicleTypes(): { id: number; label: string }[] {
    return [...this.vehicleTypes].map(([id, label]) => ({ id, label }));
  }
}
