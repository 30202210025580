import { LibTranslation } from './lib-translation.model';

export const libTranslationFI: LibTranslation = {
  common: {
    errorMessage: 'On tapahtunut virhe',
    button: {
      close: 'Sulje',
      cancel: 'Peruuta',
      apply: 'Käytä',
      assign: 'Määrää',
      save: 'Tallenna',
      confirm: 'Vahvista',
      select: 'Valitse',
    },
  },
  toolbar: {
    navigation: {
      order: 'Tilaukset',
      history: 'Historia',
      home: 'Koti',
      outcome: 'Tulosseuranta',
      planning: 'Suunnittelu',
      administration: 'Hallinto',
      routes: 'Rivit',
    },
    userMenu: {
      operatorBtn: 'Valitse operaattori',
      subheadingRole: 'Roolini:',
      subheadingRoles: 'Roolini:',
      subheadingOrg: 'Organisaationi:',
      title: 'Profiili',
      logout: 'Kirjaudu ulos',
      manageGarage: 'Hallinnoi parkkiani',
      manageOperatorUsers: 'Hallinnoi käyttäjiä',
      userManual: 'Ohjekirja',
    },
    notifications: {
      title: 'Ilmoitukset',
      markAsRead: 'Merkitse luetuksi',
      seeAll: 'Näytä kaikki',
      noNotifications: 'Ei ilmoituksia',
      goTo: 'Siirry',
      alert: {
        openDriveOrder: 'Avaa ajo-ohje',
        reconnecting: 'Yhteys reaaliaikapäivityksiin on katkennut. Yritetään uudelleen yhdistää palvelimeen.',
        reconnected: 'Yhteys palvelimeen palautettu.',
      },
      driveOrderInquiryCreatedNotification: {
        title: 'Uusi saapuva pyyntö',
        severalDriveOrdersMessage: 'Sinulla on {0} uutta pyyntöä',
        oneDriveOrderMessage: 'Sinulla on {0} uusi pyyntö',
      },
      driveOrderAssignedNotification: {
        title: 'Uusi osoitus',
        severalDriveOrdersMessage: 'Sinulle on osoitettu {0} uutta kuljetusta',
        oneDriveOrderMessage: 'Sinulle on osoitettu {0} uusi kuljetus',
      },
      driveOrderActivationReminderNotification: {
        title: 'Muistutus ajotilauksen aktivoinnista',
        driveOrderActivationReminderMessage:
          'Kuljettaja {0} ei ole aloittanut ajoa kuljetusmääräyksellä {1} aloitusaikaan {2}',
      },
      possibleLateFirstDepartureNotification: {
        title: 'Eteneminen ei aloitettu ajojärjestykselle {0}',
        message: 'Ajojärjestys {0} vuorolla {1}, aloitusaika kello {2}, ei ole aloittanut etenemistä.',
      },
      orderCreated: {
        title: 'Uusi tilaus, {0}',
        productBasedMessage: 'TODO',
        legacyMessage: '{0} {1} - {2}',
      },
      orderEdited: {
        title: 'Muutettu tilaus, {0}',
        productBasedMessage: 'TODO',
        legacyMessage: '{0} {1} - {2}',
      },
      orderCancelled: {
        title: 'Peruutus, {0}',
        productBasedMessage: 'TODO',
        legacyMessage: '{0} {1} - {2}',
      },
    },
    search: {
      select: {
        orderId: 'Tilausnumero',
        driveOrderId: 'Ajo-ohje',
        tripId: 'Matka',
        unboundOrderId: 'Vapaa tilaus',
      },
      alertMsg: {
        notANumberMessage: 'Virheellinen numero. Anna vain numeroita.',
        notFoundMessage: 'Järjestelmässä ei ole tilausta, joka vastaa annettua numeroa.',
      },
    },
  },
  vehicleTypes: {
    bigBus: 'Iso linja-auto',
    smallBus: 'Pieni linja-auto',
    doubleDecker: 'Kaksikerroksinen',
    taxi: 'Taksi',
    bigTaxi: 'Iso taksi',
    wheelchairTaxi: 'Pyörätuolitaksi',
    standbyBus: 'Valmiuslinja-auto',
    standbyWheelchairTaxi: 'Valmiuspyörätuolitaksi',
    customerHost: 'Asiakasemäntä',
    busCoordinator: 'Bussikoordinaattori',
    car: 'Henkilöauto',
  },
  order: {
    trip: {
      cancelReason1: 'Virheellinen tilaus',
      cancelReason2: 'Peruttu tilaajan toimesta',
      cancelReason3: 'Tilauksen muutos',
      cancelReason4: 'Ei toteutettu',
      cancelReason5: 'Suorittamatta jäänyt',
      cancelReason6: 'Muut syyt',
      cancelReason7: 'Korvattu',
    },
    tripStatus: {
      upcoming: 'Tuleva',
      ongoing: 'Käynnissä',
      completed: 'Päättynyt',
      cancelled: 'Peruttu',
    },
    orderType: {
      urgent: 'Kiireellinen',
      unplanned: 'Suunnittelematon',
      planned: 'Suunniteltu',
      unbound: 'Vapaa tilaus',
    },
    orderRow: {
      productType: {
        roundTrip: 'Sukkulabussi',
        transfer: 'Transfer',
      },
    },
  },
  search: {
    filter: {
      startDateTime: 'aloituspäivämäärä',
      endDateTime: 'lopetuspäivämäärä',
      hours: 'Tuntia',
      minutes: 'Minuuttia',
      resetTime: 'Nollaa aika',
    },
  },
  api: {
    error: {
      forbidden: 'Ei lupaa (tila {0})',
      noConnection: 'Yhteyttä palvelimeen ei ole',
      unauthorized: 'Ei lupaa (tila {0})',
      unknown: 'Tuntematon virhe',
      errorId: 'Virhe-ID',
    },
    success: {
      toggleGarageSuccess: 'Garagen tila muutettu',
      createUserSuccess: 'Käyttäjä luotu',
      deleteUserSuccess: 'Käyttäjä poistettu',
      deleteUsersSuccess: 'Käyttäjät poistettu',
      updateUserSuccess: 'Käyttäjä päivitetty',
      resetPasswordSuccess: 'Salasana palautettu',
      assignSubcontractorToDriveOrderSuccess: 'Aliurakoitsija määritelty',
      assignVehicleToDriveOrderSuccess: 'Ajoneuvo määritelty',
      assignDriverToDriveOrderSuccess: 'Kuljettaja määritelty',
      changeStartTimeSuccess: 'Aloitusaika muutettu',
      saveTrainNumberSuccess: 'Junanumero tallennettu',
      savePassengerCountSuccess: 'Matkustajien määrä tallennettu',
      saveCommentSuccess: 'Kommentti tallennettu',
      cancelTripsSuccess: {
        oneTrip: 'Matka on peruttu',
        twoOrMoreTrips: 'Matkat on peruttu',
        allOrRestOfTrips: 'Ajojärjestys on peruttu',
      },
      replaceTripsSuccess: {
        oneTrip: 'Matka on korvattu',
        twoOrMoreTrips: 'Matkat on korvattu',
        allOrRestOfTrips: 'Ajojärjestys on korvattu',
      },
      splitTripsSuccess: {
        oneTrip: 'Matka on jaettu',
        twoOrMoreTrips: 'Matkat on jaettu',
        allOrRestOfTrips: 'Ajojärjestys on jaettu',
      },
      duplicateTripsSuccess: {
        oneTrip: 'Matka on vahvistettu',
        twoOrMoreTrips: 'Matkat on vahvistettu',
        allOrRestOfTrips: 'Ajojärjestys on vahvistettu',
      },
      changeVehicleTypeSuccess: 'Fordonstyp har ändrats',
    },
  },
};
